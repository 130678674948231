<template>







  <v-card>

    <v-dialog v-model="showAlertModal" max-width="450">
      <v-card>
        <v-card-text>
          <div id="icon">
            <v-icon @click="showAlertModal = false" color="primary" id="icon-child" size="45">
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </div>

          <v-container>
            <span class="text-h6">The form is ideally filled by Counsellor Cum Technician.
              <br />
              This form is <strong> Not filled</strong>
              yet.</span>
            <br /><br />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> step 1 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> step 2 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3"> step 3 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 4" step="4"> step 4 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="5"> step 5 </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>

        <v-stepper-content step="1">
          <v-card-title class="justify-center">
            <span class="text-h5"><strong> POTENTIAL EYE DONOR REFERRAL INFORMATION</strong></span>
          </v-card-title>

          <v-row class="pt-3 mx-3">
            <v-col cols="12" md="4">
              <v-datetime-picker v-model="deathNotificationDate" label="Death Notification (Date Time)*"
                time-format="HH:mm" :readonly="readOnlyField1 === true" 
                date-format="dd-MM-yyyy"
                :text-field-props="textFieldProps"
                :error="errr0 === false"></v-datetime-picker>

              <!-- <v-text-field label-date VDatetimePicker ></v-text-field> -->
            </v-col>
          </v-row>
          <v-row class="mt-0 mx-3">
            <v-col cols="12" md="7">
              <v-datetime-picker v-model="consignmentdate"
                label="If Eye Donation Center, Consignment Notification Date-Time*" time-format="HH:mm"
                date-format="dd-MM-yyyy"
                :readonly="readOnlyField1 === true" :error="errr40 === false"></v-datetime-picker>
              <!-- <v-text-field
                v-model="preValueList.consignment_notification_date_time"
                readonly
                label="If Eye Donation Center, Consignment Notification Date-Time"
              >
              </v-text-field> -->
            </v-col>
          </v-row>

          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <v-autocomplete v-model="preValueList.referral_method" :items="roleList" @change="itemdata()"
                label="Referral Method*" :readonly="readOnlyField1 === true" :error="errr === false"></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4" v-if="preValueList.referral_method != 'HCRP Motivated'">
              <v-text-field v-model="preValueList.donation_counselor_volunteer" label="Donation Counsellor Volunteer*"
                :readonly="readOnlyField1 === true" :error="errr1 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.referral_method != 'HCRP Motivated'">
              <v-text-field v-model="preValueList.second_donation_counselor_volunteer" label="2nd Counsellor Volunteer"
                :readonly="readOnlyField1 === true"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.referral_method == 'HCRP Motivated'">
              <v-autocomplete v-model="preValueList.donation_counsellor" @change="callName()" :items="ListItem"
                item-text="name" label="Donation Counsellor" :readonly="readOnlyField1 === true"
                :error="errr11 === false"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.referral_method == 'HCRP Motivated'">
              <v-text-field v-model="preValueList.second_donation_counsellor" label="2nd Counsellor"
                :readonly="readOnlyField1 === true"></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="preValueList.caller_full_name" label="Caller Full Name"
                :readonly="readOnlyField1 === true"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-datetime-picker v-model="dateTimeOfCall" date-format="dd-MM-yyyy" time-format="HH:mm" 
              label="Date Time of Call"
                :readonly="readOnlyField1 === true">
              </v-datetime-picker>
            </v-col>
            <!-- <v-col cols="12" md="4">
              <v-text-field
                v-model="formList.relationship"
                label="Relationship"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="4">
              <v-autocomplete v-model="preValueList.relation_with_caller" :items="Relationship" label="Relationship"
                :readonly="readOnlyField1 === true" @change="restrelation()">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.relation_with_caller == 'Other'">
              <v-text-field v-model="preValueList.relation_with_caller_other_name" label="Other"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.caller_phone_no" label="Phone no*." counter="10"
                @keypress="onlyNumber" @paste.prevent :readonly="readOnlyField1 === true" :error="errr2 === false">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete v-model="preValueList.donation_center" :items="HospitalList"
                label="Hospital/Eye Donation Center*" item-text="hospital_name" item-value="id"
                :readonly="readOnlyField1 === true" :error="errr3 === false"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete v-model="preValueList.unit" :items="Unit" label="Unit type*"
                :readonly="readOnlyField1 === true" :error="errr4 === false">
              </v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.unit_description" label="Unit Description"
                :readonly="readOnlyField1 === true">
              </v-text-field>
            </v-col> -->
            <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.unit_details" label="Unit Details*"
                :readonly="readOnlyField1 === true" :error="errr5 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete v-model="preValueList.body_location" :items="BodyLocation" label="Body Location"
                :readonly="readOnlyField1 === true">
              </v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" md="4" v-if="preValueList.body_location == 'Residence'">
              <v-text-field v-model="preValueList.body_location_description" label="Body Location Description"
                :readonly="readOnlyField1 === true"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.body_location == 'Hospital'">
              <v-text-field v-model="preValueList.body_location_description" label="Body Location Description"
                :readonly="readOnlyField1 === true"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.body_location == 'Mortuary'">
              <v-text-field v-model="preValueList.body_location_description" label="Body Location Description"
                :readonly="readOnlyField1 === true"></v-text-field>
            </v-col> -->
            <!-- <v-col cols="12" md="4">
              <v-datetime-picker v-model="bodyAvailableUntil" time-format="HH:mm" 
               date-format="dd-MM-yyyy"
              label="Body Available Until"
                :readonly="readOnlyField1 === true">
              </v-datetime-picker>
            </v-col> -->
            <v-col cols="12" md="12">
              <v-textarea v-model="preValueList.recovery_address_directions" label="Recovery Address/Directions"
                rows="4" row-height="15" :readonly="readOnlyField1 === true"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="pt-0 mt-0">
              <v-btn class="float-right mr-6 mb-3" color="primary" text @click="e1 = 2"> Next </v-btn>
              <v-btn class="float-right mr-3 mb-3" v-if="saveBtn" color="primary" @click="FormAFirst()"> Save </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card-title class="justify-start">
            <span class="text-h5"><strong> DONOR INFORMATION</strong></span>
          </v-card-title>

          <v-row class="mx-3 pt-3">
            <v-col cols="12" md="6">
              <v-text-field v-model="preValueList.family_name" label="Family Name" readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="preValueList.given_name" label="Given Name" readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-datetime-picker v-model="dobDate" 
               date-format="dd-MM-yyyy" time-format="HH:mm"  label="DOB"
                :readonly="readOnlyField1 === true"></v-datetime-picker>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="preValueList.age" label="Age" @keypress="onlyAge" @paste.prevent readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete v-model="preValueList.sex" :items="Sex" label="Sex" readonly> </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="preValueList.religion" label="Religion*" :readonly="readOnlyField1 === true"
                :error="errr6 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field v-model="preValueList.height" label="Height in inch*" :readonly="readOnlyField1 === true"
                :error="errr7 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group row class="mt-0" v-model="preValueList.height_report" :readonly="readOnlyField1 === true"
                :error="errr12 === false">
                <v-radio name="Parents_name" label="Estimated" value="Estimated"></v-radio>
                <v-radio name="Parents_name" label="Reported" value="Reported"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field v-model="preValueList.weight" label="Weight in kg*" @keypress="Onweight"
                :readonly="readOnlyField1 === true" :error="errr8 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group row class="mt-0" v-model="preValueList.weight_report" :readonly="readOnlyField1 === true"
                :error="errr13 === false">
                <v-radio name="Parents_name" label="Estimated" value="Estimated"></v-radio>
                <v-radio name="Parents_name" label="Reported" value="Reported"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="3">
              <v-text-field v-model="preValueList.mr_no" label="MR No" :readonly="readOnlyField1 === true"
                :error="errr9 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-datetime-picker v-model="addimissionDateTime" label="Admission Date Time"    
              date-format="dd-MM-yyyy" 
               time-format="HH:mm"
                :readonly="readOnlyField1 === true"></v-datetime-picker>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.cause_of_death" label="Primary Cause of Death" readonly>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12">
              <v-textarea v-model="preValueList.circumstances_leading_to_death" label="Circumstances Leading to Death*"
                rows="4" :readonly="readOnlyField1 === true" row-height="15" :error="errr10 === false"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.attending_physician" label="Attending Physician"
                :readonly="readOnlyField1 === true"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.patient_given_donor_name" label="Patient Name" readonly>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" class="float-left ml-5" text @click="refresh()"> Previous </v-btn>
              <v-btn color="primary" class="mx-3" v-if="saveBtn" @click="FormASecond()"> Save </v-btn>
              <v-btn color="primary" class="mr-5" text @click="e1 = 3"> Next </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card-title class="justify-start">
            <span class="text-h5"><strong> PRELIMINARY TISSUE SCREENING INFORMATION</strong></span>
          </v-card-title>

          <v-row class="mx-3 pt-3">
            <v-col cols="12" md="4">
              <v-text-field v-model="deathDateTime" date-format="dd-MM-yyyy" time-format="HH:mm:ss" label="Death DateTime" readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mb-1">Death DateTime Actual/Estimated</p>
              <v-radio-group row class="mt-0" v-model="preValueList.death_datetime_actual_estimated"
                :readonly="readOnlyField1 === true">
                <v-radio name="Parents_name" label="Actual" value="Actual"></v-radio>
                <v-radio name="Parents_name" label="Estimated" value="Estimated"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <v-autocomplete label="Donor On respirator/ventilator*"
                v-model="preValueList.donor_on_respirator_vantilator"
                @change="rest1(preValueList.donor_on_respirator_vantilator)" :items="['Yes', 'No']"
                :readonly="readOnlyField1 === true" :error="errr14 === false"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.donor_on_respirator_vantilator == 'Yes'">
              <v-text-field v-model="preValueList.time_of_vantilator" label="How Long ?*"
                :readonly="readOnlyField1 === true" :error="errr15 === false"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mb-1">History of HIV ?*</p>
              <v-radio-group row class="mt-0" v-model="preValueList.history_of_hiv" :readonly="readOnlyField1 === true"
                :error="errr16 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
                <v-radio name="active" label="NRA" value="NRA"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mb-1">History of HBV ?*</p>
              <v-radio-group row class="mt-0" v-model="preValueList.history_of_hbv" :readonly="readOnlyField1 === true"
                :error="errr17 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
                <v-radio name="active" label="NRA" value="NRA"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mb-1">History of HCV ?*</p>
              <v-radio-group row class="mt-0" v-model="preValueList.history_of_hcv" :readonly="readOnlyField1 === true"
                :error="errr18 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
                <v-radio name="active" label="NRA" value="NRA"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mb-1">Ocular Hx*</p>
              <v-radio-group :error="errr19 === false" row class="mt-0" v-model="preValueList.ocular_hx"
                @change="restOne(preValueList.ocular_hx)" :readonly="readOnlyField1 === true">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
                <v-radio name="active" label="NRA" value="NRA"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8" v-if="preValueList.ocular_hx == 'Yes'">
              <v-textarea v-model="preValueList.ocular_hx_description" label="If Yes Describe" rows="2" row-height="15"
                :readonly="readOnlyField1 === true"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mb-1">History of Cancer ?*</p>
              <v-radio-group row class="mt-0" v-model="preValueList.history_of_cancer"
                :readonly="readOnlyField1 === true" @change="rest11(preValueList.history_of_cancer)"
                :error="errr20 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
                <v-radio name="active" label="NRA" value="NRA"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8" v-if="preValueList.history_of_cancer == 'Yes'">
              <v-textarea v-model="preValueList.history_of_cancer_description" label="If Yes Describe" rows="2"
                row-height="15" :readonly="readOnlyField1 === true"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mb-1">Dementia/Alzheimer's/CNS Disease*</p>
              <v-radio-group row class="mt-0" :readonly="readOnlyField1 === true"
                v-model="preValueList.dementia_alzheimer_cnd_deases"
                @change="rest12(preValueList.dementia_alzheimer_cnd_deases)" :error="errr21 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
                <v-radio name="active" label="NRA" value="NRA"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8" v-if="preValueList.dementia_alzheimer_cnd_deases == 'Yes'">
              <v-textarea v-model="preValueList.dementia_alzheimer_cnd_deases_description" label="If Yes Describe"
                rows="2" row-height="15" :readonly="readOnlyField1 === true"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mb-1">Isolation*</p>
              <v-radio-group row class="mt-0" v-model="preValueList.isolation" @change="rest13(preValueList.isolation)"
                :readonly="readOnlyField1 === true" :error="errr22 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8" v-if="preValueList.isolation == 'Yes'">
              <v-textarea v-model="preValueList.isolation_description" label="If Yes Describe" rows="2" row-height="15"
                :readonly="readOnlyField1 === true"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mb-1">Signs/Symptoms of Systemic Infection*</p>
              <v-radio-group row class="mt-0" v-model="preValueList.systematic_infection"
                :readonly="readOnlyField1 === true" @change="rest14(preValueList.systematic_infection)"
                :error="errr23 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="8" v-if="preValueList.systematic_infection == 'Yes'">
              <v-textarea v-model="preValueList.systematic_infection_description" label="If Yes Describe" rows="2"
                row-height="15" :readonly="readOnlyField1 === true"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <v-autocomplete label="IV fluid given in the Last hour?*" v-model="preValueList.iv_fluid_last_hour"
                :items="['Yes', 'No']" :readonly="readOnlyField1 === true" :error="errr24 === false"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.iv_fluid_last_hour == 'Yes'">
              <v-text-field v-model="preValueList.iv_fluid_amount" label="Fluid amount in ml*" @keypress="onlyNumber"
                @paste.prevent :readonly="readOnlyField1 === true" :error="errr25 === false"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <v-autocomplete label="Blood given in the last 48 hours?*" v-model="preValueList.blood_given_last_48hours"
                :items="['Yes', 'No']" :readonly="readOnlyField1 === true" :error="errr26 === false"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.blood_given_last_48hours == 'Yes'">
              <v-text-field v-model="preValueList.blood_given_amount" label="Blood amount in Units*"
                @keypress="onlyNumber" @paste.prevent :error="errr27 === false" :readonly="readOnlyField1 === true">
              </v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="4" v-if="formList.blood_given_last_48hours == 'Yes'">
              <v-text-field
                v-model="formList.blood_given_amount_ml"
                label="Blood amount in ml"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col> -->
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <v-autocomplete label="Transferred from other facility?"
                v-model="preValueList.transferred_from_other_facility" :items="['Yes', 'No']"
                :readonly="readOnlyField1 === true"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" v-if="preValueList.transferred_from_other_facility == 'Yes'">
              <v-text-field v-model="preValueList.transferred_time" label="Transferred from where/when ?"
                :readonly="readOnlyField1 === true" :error="errr28 === false"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="12">
              <v-textarea v-model="preValueList.medical_history"
                label='Medical History(Also explain any History marked above as "Yes*")' rows="3" row-height="15"
                :readonly="readOnlyField1 === true"></v-textarea>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea v-model="preValueList.social_behavioral_hx" label="Social and Behavioral Hx" rows="2"
                row-height="15" :readonly="readOnlyField1 === true"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" class="float-left ml-5" text @click="refresh1()"> Previous </v-btn>
              <v-btn color="primary" class="mx-3" v-if="saveBtn" @click="FormAThird()"> Save </v-btn>
              <v-btn color="primary" class="mr-5" text @click="e1 = 4"> Next </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card-title class="justify-start">
            <span class="text-h5"><strong> MEDICO-LEGAL INFORMATION</strong></span>
          </v-card-title>

          <v-row class="mx-3 pt-3">
            <v-col cols="12" md="4">
              <p class="mb-1">Reportable as Medico-Legal Case?*</p>
              <v-radio-group row class="mt-0" v-model="preValueList.medico_legal_case"
                :readonly="readOnlyField1 === true" @change="restt(preValueList.medico_legal_case)"
                :error="errr29 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <p class="mb-1">Permission for Donation?*</p>
              <v-radio-group row class="mt-0" v-model="preValueList.permission_for_donation"
                :readonly="readOnlyField1 === true" :error="errr30 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
                <v-radio name="active" label="N/A" value="N/A"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mx-3" v-if="preValueList.medico_legal_case == 'Yes'">
            <v-col cols="12" md="6">
              <v-text-field v-model="preValueList.police_medico_legal_case" label="Police/Medico-Legal Case*"
                :readonly="readOnlyField1 === true" :error="errr31 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="preValueList.medico_legal_facility" label="Medico-Legal Facility*"
                :readonly="readOnlyField1 === true" :error="errr32 === false"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3" v-if="preValueList.medico_legal_case == 'Yes'">
            <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.released_by" label="Released By*" :readonly="readOnlyField1 === true"
                :error="errr33 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="preValueList.released_by_phone" label="Phone*" @keypress="onlyNumber"
                :readonly="readOnlyField1 === true" :error="errr34 === false" counter="10"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-3" v-if="preValueList.medico_legal_case == 'Yes'">
            <v-col cols="12" md="12">
              <v-textarea v-model="preValueList.other_notes"
                label="Medico-legal or Hospital Restrictions, Special Requests, or Other Notes" rows="3" row-height="15"
                :readonly="readOnlyField1 === true"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mx-3">
            <v-col cols="12" md="4">
              <p class="mb-0">Autopsy*</p>
              <v-radio-group row class="mt-0" v-model="preValueList.autospy" @change="rest(preValueList.autospy)"
                :readonly="readOnlyField1 === true" :error="errr35 === false">
                <v-radio name="active" label="Yes" value="Yes"></v-radio>
                <v-radio name="active" label="No" value="No"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.autospy == 'Yes'">
              <v-text-field v-model="preValueList.autospy_performed_by" label="Autopsy Performed By*"
                :readonly="readOnlyField1 === true" :error="errr36 === false"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="preValueList.autospy == 'Yes'">
              <v-text-field v-model="preValueList.autospy_location" label="Autopsy Location*"
                :readonly="readOnlyField1 === true" :error="errr37 === false"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" class="float-left ml-5" text @click="refresh2()"> Previous </v-btn>
              <v-btn color="primary" class="mx-3" v-if="saveBtn" @click="FormAFour()"> Save </v-btn>
              <v-btn color="primary" class="mr-5" text @click="e1 = 5"> Next </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card-title class="d-flex flex-column align-start">
            <p class="text-h5 text-left"><strong> OUTCOME</strong></p>
            <p class="body-2">
              (If the donor family is approached please do not fill in the outcome on this form and move on to FORM B)
            </p>
          </v-card-title>
          <v-radio-group class="mt-0" v-model="preValueList.outcome" :error="errr38 === false">
            <v-row class="mx-3 pt-3">
              <v-col cols="12" md="4">
                <v-radio name="active" value="Not Suitable" label="Not Screened" @click="restradio()"
                  :readonly="readOnlyField === true"></v-radio>
                <!-- <v-checkbox 
                label="Not Suitable" 
                v-model="preValueList.not_screened" 
                value="true"
                class="mb-0"
                hide-details
              ></v-checkbox> -->
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete :items="LocationsList" v-if="preValueList.outcome == 'Not Suitable'" label="Outcome"
                  v-model="preValueList.not_screened_reason" :readonly="readOnlyField === true"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-textarea label="Other reason why not screened" v-model="preValueList.not_screened_reason_description"
                  v-if="
                    preValueList.not_screened_reason == 'Other reason why not screened' &&
                    preValueList.outcome == 'Not Suitable'
                  " row-height="15" rows="1" :readonly="readOnlyField === true"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mx-3">
              <v-col cols="12" md="4">
                <v-radio name="active" value="Screened - Not Suitable" label="Screened - Not Suitable"
                  @click="restradio1()" :readonly="readOnlyField === true"></v-radio>
                <!-- <v-checkbox 
                label="Screened - Not Suitable" 
                v-model="preValueList.not_suitable" 
                value="true"
                hide-details
                class="mb-0"
              ></v-checkbox> -->
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete :items="bodyLocationsList" v-if="preValueList.outcome == 'Screened - Not Suitable'"
                  label="Outcome" v-model="preValueList.not_suitable_reason" :readonly="readOnlyField === true">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-textarea label="Other reason why screened, but not suitable"
                  v-model="preValueList.not_suitable_reason_description" v-if="
                    preValueList.not_suitable_reason == 'Other reason why screened, but not suitable' &&
                    preValueList.outcome == 'Screened - Not Suitable'
                  " row-height="15" rows="1" :readonly="readOnlyField === true"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mx-3 mb-3">
              <v-col cols="12" md="4">
                <v-radio name="active" value="Suitable - Not approached" label="Suitable - Not approached"
                  @click="restradio2()" :readonly="readOnlyField === true"></v-radio>
                <!-- <v-checkbox 
                label="Suitable - Not approached" 
                v-model="preValueList.not_approched" 
                value="true"
                hide-details
                class="mb-0"
              ></v-checkbox> -->
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete :items="SuitableList" v-if="preValueList.outcome == 'Suitable - Not approached'"
                  label="Outcome" v-model="preValueList.not_approched_reason" :readonly="readOnlyField === true">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-textarea label="Other reason why not approached"
                  v-model="preValueList.not_approched_reason_description" v-if="
                    preValueList.not_approched_reason == 'Other reason why not approached' &&
                    preValueList.outcome == 'Suitable - Not approached'
                  " row-height="15" rows="1" :readonly="readOnlyField === true"></v-textarea>
              </v-col>
              <v-col cols="12" sm="4">
                <v-radio name="active" value="Donor Family Approaced" label="Donor Family Approached"
                  :readonly="readOnlyField === true"></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
          <v-row class="mx-3">
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" class="float-left" text @click="refresh3()"> Previous </v-btn>
              <v-btn color="primary" v-if="saveBtn" @click="same()"> Save </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

      </v-stepper-items>

      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-stepper>
  </v-card>



</template>

<style>
 #icon{
  width:100%;
  text-align: right;    
 }

 #icon-child{
  margin-right: -10px;
  margin-top: 5px;
 }
</style>

<script>
import CounsellorService from '@/service/Counsellor.service'
import donorService from '@/service/Donor.service'
import systemService from '@/service/System.service'
import { mdiMagnify, mdiCloseCircleOutline, mdiGithub } from '@mdi/js'
import moment from 'moment'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
const userId = localData.user_id
import { api } from '@/config/config'
// import { VDatetimePicker } from 'v-datetime-field'

// const userName = localData.user_name
// const userNumber = localData.user_number

export default {
  // components: { VDatetimePicker },
  data() {
    return {
      errr11: true,
      errr0: true,
      errr: true,
      errr1: true,
      errr2: true,
      errr3: true,
      errr4: true,
      errr5: true,
      errr6: true,
      errr7: true,
      errr8: true,
      errr9: true,
      errr10: true,
      errr11: true,
      errr12: true,
      errr13: true,
      errr14: true,
      errr15: true,
      errr16: true,
      errr17: true,
      errr18: true,
      errr19: true,
      errr20: true,
      errr21: true,
      errr22: true,
      errr23: true,
      errr24: true,
      errr25: true,
      errr26: true,
      errr27: true,
      errr28: true,
      errr29: true,
      errr30: true,
      errr31: true,
      errr32: true,
      errr33: true,
      errr34: true,
      errr35: true,
      errr36: true,
      errr37: true,
      errr38: true,
      errr40: true,

 
      formtype: '',
      Notscreen: 'false',
      ScreenedNotscreen: 'false',
      showAlertModal: false,
      Suitable: 'false',
      listLoading: false,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      e1: 1,
      LocationsList: ['Identity of decedent not known', 'Other reason why not screened'],
      SuitableList: [
        'Police does not allow donation',
        'family members not available',
        'family members aggravated or emotional',
        'Other reason why not approached',
      ],
      bodyLocationsList: [
        'Medical Contraindication',
        'Hemodilution',
        'Ocular Hx or Sx, high risk',
        'Trauma to eyes',
        'Decedent has no family or unclaimed body',
        'Does not meet criteria for death to preservation time',
        'Other reason why screened, but not suitable',
      ],
      roleList: ['Voluntary-Family Initiated', 'Voluntary Volunteer Motivated', 'HCRP Motivated'],
      Sex: ['Male', 'Female'],
      Relationship: ['Son', 'Wife', 'Daughter', 'Other'],
      Unit: ['ICU', 'Casualty', 'Emergency', 'Trauma Center', 'Mortuary', 'Ward'],
      BodyLocation: ['Residence', 'Hospital', 'Mortuary'],
      HospitalList: [{ id: '', hospital_name: '' }],
      ListItem: [{ name: '' }],
      readOnlyField: false,
      readOnlyField1: false,

      saveBtn: true,
      textFieldProps: {


      },
      // timePickerProps:{
      //    readonly
      // },

      //preValueList:[],

      preValueList: {
        id: '',
        donation_counsellor: '',
        second_donation_counsellor: '',
        not_suitable_reason: '',
        not_screened_reason: '',
        relation_with_caller_other_name: '',
        death_notification: '',
        consignment_notification_date_time: '',
        referral_method: '',
        donation_counselor_volunteer: '',
        second_donation_counselor_volunteer: '',
        caller_full_name: '',
        date_time_of_call: '',
        relation_with_caller: '',
        caller_phone_no: '',
        donation_center: '',
        unit: '',
        // unit_description: '',
        unit_details: '',
        body_location: '',
        // body_location_description: '',
        // body_available_until: '',
        recovery_address_directions: '',
        family_name: '',
        given_name: '',
        dob: '',
        age: '',
        sex: '',
        religion: '',
        height: '',
        height_report: '',
        weight: '',
        weight_report: '',
        mr_no: '',
        addimission_date_time: '',
        cause_of_death: '',
        circumstances_leading_to_death: '',
        attending_physician: '',
        patient_given_donor_name: '',
        death_datetime_actual_estimated: '',
        donor_on_respirator_vantilator: '',
        time_of_vantilator: '',
        history_of_hiv: '',
        history_of_hbv: '',
        history_of_hcv: '',
        history_of_cancer: '',
        dementia_alzheimer_cnd_deases: '',
        ocular_hx: '',
        isolation: '',
        systematic_infection: '',
        iv_fluid_last_hour: '',
        iv_fluid_amount: '',
        blood_given_last_48hours: '',
        blood_given_amount: '',
        transferred_from_other_facility: '',
        transferred_time: '',
        medical_history: '',
        social_behavioral_hx: '',
        medico_legal_case: '',
        permission_for_donation: '',
        police_medico_legal_case: '',
        medico_legal_facility: '',
        released_by: '',
        released_by_phone: '',
        other_notes: '',
        autospy: '',
        autospy_performed_by: '',
        autospy_location: '',
        not_screened: '',
        not_suitable: '',
        not_approched: '',
        history_of_cancer_description: '',
        dementia_alzheimer_cnd_deases_description: '',
        isolation_description: '',
        systematic_infection_description: '',
        ocular_hx_description: '',
        not_screened_reason_description: '',
        outcome: '',
        not_suitable_reason_description: '',
        not_approched_reason_description: '',
        not_approched_reason: '',
      },
    }
  },

  setup() {
    return {
      icons: {
        mdiCloseCircleOutline,
      },
    }
  },

  computed: {
    deathNotificationDate: {
      get() {
        return moment.utc(this.preValueList.death_notification).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(
          this.preValueList,
          'death_notification',
          moment(value).format('YYYY-MM-DD HH:mm'),
        )
      },
    },

    consignmentdate: {
      get() {
        return moment.utc(this.preValueList.consignment_notification_date_time).format('DD-MM-YYYY HH:mm');
      },
      set(value) {
        this.$set(
          this.preValueList,
          'consignment_notification_date_time',
          moment(value).format('YYYY-MM-DD HH:mm'),
        )
      },
    },

    dateTimeOfCall: {
      get() {
        return moment.utc(this.preValueList.date_time_of_call).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(
          this.preValueList,
          'date_time_of_call',
          moment(value).format('YYYY-MM-DD HH:mm'),
        )
      },
    },

    // bodyAvailableUntil: {
    //   get() {
    //     return moment.utc(this.preValueList.body_available_until).format('DD-MM-YYYY HH:mm')
    //   },
    //   set(value) {
    //     this.$set(
    //       this.preValueList,
    //       'body_available_until',
    //       moment(value).format('YYYY-MM-DD HH:mm'),
    //     )
    //   },
    // },

    dobDate: {
      get() {
        return moment.utc(this.preValueList.dob).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(this.preValueList, 'dob', moment(value).format('YYYY-MM-DD HH:mm'))
      },
    },

    addimissionDateTime: {
      get() {
        return moment.utc(this.preValueList.addimission_date_time).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(this.preValueList,'addimission_date_time',moment(value).format('YYYY-MM-DD HH:mm'),)
      },
    },

    deathDateTime: {
      get() {
        return moment.utc(this.preValueList.date_of_time_of_death).format('DD-MM-YYYY HH:mm')
      },
    },
  },

  watch: {
    options: {
      handler() {
        this.donorDeatilView()
        this.GetHospitalList()
        this.getdonorList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.donorDeatilView()
    this.GetHospitalList()
    this.getdonorList()
    this.getCounselorList()
  },

  methods: {
    refresh() {
      this.e1 = 1
      this.donorDeatilView()
    },
    refresh1() {
      this.e1 = 2
      this.donorDeatilView()
    },
    refresh2() {
      this.e1 = 3
      this.donorDeatilView()
    },
    refresh3() {
      this.e1 = 4
      this.donorDeatilView()
    },
    itemdata() {
      if (this.preValueList.referral_method != 'HCRP Motivated') {
        this.preValueList.caller_full_name = ''
        this.preValueList.donation_counsellor = ''
        this.preValueList.second_donation_counsellor = ''
      }
      if (this.preValueList.referral_method == 'HCRP Motivated') {
        this.preValueList.relation_with_caller = 'Other'
        this.preValueList.donation_counselor_volunteer = ''
        this.preValueList.second_donation_counselor_volunteer = ''
      } else {
        this.preValueList.relation_with_caller = ''
      }
    },
    callName() {
      this.preValueList.caller_full_name = this.preValueList.donation_counsellor
    },

    async getCounselorList() {
      const service = new CounsellorService()
      let response = await service.counsellorList()

      if (response) {
        this.ListItem = response.data
      } else {
        this.ListItem = []
        this.totallist = 0
      }
    },
    same() {
      if (this.preValueList.outcome == '' || this.preValueList.outcome == null) {
        this.errr38 = false
        return false, (this.snackbarText = 'Please Select Outcome'), (this.snackbar = true)
      } else {
        this.errr38 = true

      }
      this.FormAFive()
      if (this.formtype.view != '0' && this.formtype.view != null) {
        if (this.preValueList.outcome == 'Donor Family Approaced') {
          this.$router.push({ name: 'Form_B' })
        } else {
          return false
        }
      }

    },

    restradio() {
      if (this.preValueList.outcome == 'Not Suitable') {
        ; (this.preValueList.not_suitable_reason = ''), (this.preValueList.not_suitable_reason_description = '')
        this.not_suitable = true;
      }
    },
    restradio1() {
      if (this.preValueList.outcome == 'Screened - Not Suitable') {
        ; (this.preValueList.not_screened_reason = ''), (this.preValueList.not_screened_reason = '')
        this.not_screened = true;
        // not_screened: '',
        // not_suitable: '',
        // not_approched: '',
      }
    },
    restradio2() {
      if (this.preValueList.outcome == 'Suitable - Not approached') {
        ; (this.preValueList.not_approched_reason = ''), (this.preValueList.not_approched_reason_description = '')
        this.not_approched = true;
      }
    },

    restrelation() {
      if (this.preValueList.relation_with_caller != 'Other') {
        this.preValueList.relation_with_caller_other_name = ''
      }
    },
    restOne(type) {
      if (type == 'No') {
        this.preValueList.ocular_hx_description = ''
      } else if (type == 'NRA') {
        this.preValueList.ocular_hx_description = ''
      }
    },
    rest11(type) {
      if (type == 'No') {
        this.preValueList.history_of_cancer_description = ''
      } else if (type == 'NRA') {
        this.preValueList.history_of_cancer_description = ''
      }
    },
    rest12(type) {
      if (type == 'No') {
        this.preValueList.dementia_alzheimer_cnd_deases_description = ''
      } else if (type == 'NRA') {
        this.preValueList.dementia_alzheimer_cnd_deases_description = ''
      }
    },
    rest13(type) {
      if (type == 'No') {
        this.preValueList.isolation_description = ''
      }
    },
    rest14(type) {
      if (type == 'No') {
        this.preValueList.systematic_infection_description = ''
      }
    },

    restt(type) {
      if (type == 'No') {
        ; (this.preValueList.police_medico_legal_case = ''),
          (this.preValueList.medico_legal_facility = ''),
          (this.preValueList.released_by = ''),
          (this.preValueList.released_by_phone = ''),
          (this.preValueList.other_notes = '')
      }
    },
    rest(type) {
      if (type == 'No') {
        ; (this.preValueList.autospy_performed_by = ''), (this.preValueList.autospy_location = '')
      }
    },
    rest1(type) {
      if (type == 'No') {
        this.preValueList.time_of_vantilator = ''
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault()
      }
    },

    onlyAge($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 2) {
        $event.preventDefault()
      }
    },
    Onweight($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 3) {
        $event.preventDefault()
      }
    },

    async donorDeatilView() {
      const DonorId = this.$route.params.donor_id
      const service = new donorService()
      let response = await service.FormAData(DonorId)
      this.preValueList = response
      this.preValueList.death_notification = moment(response.death_notification).subtract("05:30").format("YYYY-MM-DD HH:mm")
      // this.preValueList.body_available_until = moment(response.body_available_until).subtract("05:30").format("YYYY-MM-DD HH:mm")
      this.preValueList.consignment_notification_date_time = moment(response.consignment_notification_date_time).subtract("05:30").format("YYYY-MM-DD HH:mm")
      this.preValueList.date_time_of_call = moment(response.date_time_of_call).subtract("05:30").format("YYYY-MM-DD HH:mm")
      this.readOnlyFun()
    },

    async readOnlyFun() {
      if (this.preValueList.outcome == '' && this.preValueList.outcome == '') {
        this.readOnlyField = false
      } else if (this.preValueList.outcome == null && this.preValueList.outcome == null) {
        this.readOnlyField = false
      } else {
        this.readOnlyField = true
      }
    },

    async GetHospitalList() {
      const service = new systemService()
      let response = await service.HospitalList()
      if (response) {
        this.HospitalList = response.data
      } else {
        this.HospitalList = []
      }
    },
    async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      if (response) {
        this.permissionData = response.permissions
        var formAData = this.permissionData[1]
        this.formtype = this.permissionData[2]
        if ((formAData.view == '1' && formAData.edit == null) || (formAData.view == '1' && formAData.edit == '0')) {

          this.readOnlyField1 = true
          if (!this.preValueList.id) {
            this.showAlertModal = true
          }

          this.saveBtn = false
        }
      } else {
        this.totallist = 0
      }
      this.listLoading = false
    },

    async FormAFirst() {
      if (this.preValueList.death_notification == '' || this.preValueList.death_notification == 'Invalid date') {
        this.errr0 = false
        return false, (this.snackbarText = 'Please Fill the death Notification(Date & Time)'), (this.snackbar = true)
      }
      if (this.preValueList.consignment_notification_date_time == '' || this.preValueList.consignment_notification_date_time == 'Invalid date') {
        this.errr40 = false
        return false, (this.snackbarText = 'Please Fill the Consignment Notification Date & Time'), (this.snackbar = true)
      } 
      if (this.preValueList.referral_method == '' || this.preValueList.referral_method == null) {
        this.errr = false
        return false, (this.snackbarText = 'Please Fill the Referral Method'), (this.snackbar = true)
      }
      if (this.preValueList.referral_method == 'Voluntary-Family Initiated' || this.preValueList.referral_method == 'Voluntary Volunteer Motivated') {
        if (this.preValueList.donation_counselor_volunteer == '' || this.preValueList.donation_counselor_volunteer == null) {
          this.errr1 = false
          return false,
            (this.snackbarText = "Please Fill the Donation Counselor Volunteer"),
            (this.snackbar = true)
        } else {
          this.errr1 = true
        }
      } 
      if (this.preValueList.referral_method == 'HCRP Motivated') {
        if (this.preValueList.donation_counsellor == '' || this.preValueList.donation_counsellor == null) {
          this.errr11 = false
          return false,
            (this.snackbarText = "Please Fill the Donation Counselor"),
            (this.snackbar = true)
        } else {
          this.errr11 = true
        }
      }
      if (this.preValueList.date_time_of_call == '' || this.preValueList.date_time_of_call == null || this.preValueList.date_time_of_call == "Invalid date") {
          this.preValueList.date_time_of_call = null
      } 
      // if (this.preValueList.body_available_until == '' || this.preValueList.body_available_until == null || this.preValueList.body_available_until == "Invalid date") {
      //     this.preValueList.body_available_until = null
      // } 
      //else if (this.preValueList.relation_with_caller == '' || this.preValueList.relation_with_caller == null) {
      //   return false, (this.snackbarText = 'Please select Relationship'), (this.snackbar = true)
      // } 
      if (this.preValueList.caller_phone_no == '' || this.preValueList.caller_phone_no == null) {
        this.errr2 = false
        return false, (this.snackbarText = 'Please Fill the Phone No'), (this.snackbar = true)
      }
      if (this.preValueList.caller_phone_no.length < 10) {
        this.errr2 = false
        return false,
          this.snackbarText = 'Please Enter the 10 digit phone number',
          this.snackbar = true
      }
      if (this.preValueList.donation_center == '' || this.preValueList.donation_center == null) {
        this.errr3 = false
        return false, (this.snackbarText = 'Please select Eye Donation Center.'), (this.snackbar = true)
      } 
      if (this.preValueList.unit == '' || this.preValueList.unit == null) {
        this.errr4 = false
        return false, (this.snackbarText = 'Please select Unit'), (this.snackbar = true)
      } 
      if (this.preValueList.unit_details == '' || this.preValueList.unit_details == null) {
        this.errr5 = false
        return false, (this.snackbarText = 'Please Fill the Unit details '), (this.snackbar = true)
      } 
      else {
        this.errr = true
        this.errr40 = true
        this.errr2 = true
        this.errr3 = true
        this.errr4 = true
        this.errr5 = true
      }
      const data = {
        donor_id: this.$route.params.donor_id,
        userid: userId,
        death_notification: this.preValueList.death_notification,
        consignment_notification_date_time: this.preValueList.consignment_notification_date_time,
        referral_method: this.preValueList.referral_method,
        donation_counsellor: this.preValueList.donation_counsellor,
        second_donation_counsellor: this.preValueList.second_donation_counsellor,
        donation_counselor_volunteer: this.preValueList.donation_counselor_volunteer,
        second_donation_counselor_volunteer: this.preValueList.second_donation_counselor_volunteer,
        caller_full_name: this.preValueList.caller_full_name,
        date_time_of_call: this.preValueList.date_time_of_call,
        relation_with_caller: this.preValueList.relation_with_caller,
        relation_with_caller_other_name: this.preValueList.relation_with_caller_other_name,
        caller_phone_no: this.preValueList.caller_phone_no,
        donation_center: this.preValueList.donation_center,
        unit: this.preValueList.unit,
        // unit_description: this.preValueList.unit_description,
        unit_details: this.preValueList.unit_details,
        body_location: this.preValueList.body_location,
        // body_location_description: this.preValueList.body_location_description,
        // body_available_until: this.preValueList.body_available_until,
        recovery_address_directions: this.preValueList.recovery_address_directions,
      }

      try {
        const response = await api.post(`donorNotification/save-form-a`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async FormASecond() {

      if (this.preValueList.addimission_date_time == "" || this.preValueList.addimission_date_time == "Invalid date" || this.preValueList.addimission_date_time === null) {
        this.preValueList.addimission_date_time = null
      }
      else {
        this.preValueList.addimission_date_time = moment.utc(this.preValueList.addimission_date_time).format('YYYY-MM-DD HH:mm')
      }
      if (this.preValueList.dob == "" || this.preValueList.dob == "Invalid date" || this.preValueList.dob === null) {
        this.preValueList.dob = null
      }
      else{
        this.preValueList.dob = moment.utc(this.preValueList.dob).format('YYYY-MM-DD HH:mm')
      }
      if (this.preValueList.religion == '' || this.preValueList.religion == null) {
        this.errr6 = false
        return false, 
        this.snackbarText = 'Please Fill the Religion.', 
        this.snackbar = true
      } 
      else if (this.preValueList.height == '' || this.preValueList.height == null) {
        this.errr7 = false
        return false, 
        this.snackbarText = 'Please Fill the Height.', 
        this.snackbar = true
      } 
      else if (this.preValueList.height_report == '' || this.preValueList.height_report == null) {
        this.errr12 = false
        return false, 
        this.snackbarText = 'Please Fill the Height Estimated or Report.', 
        this.snackbar = true
      } 
      else if (this.preValueList.weight == '' || this.preValueList.weight == null) {
        this.errr8 = false
        return false, 
        this.snackbarText = 'Please Fill the Weight.', 
        this.snackbar = true
      } 
      else if (this.preValueList.weight_report == '' || this.preValueList.weight_report == null) {
        this.errr13 = false
        return false, 
        this.snackbarText = 'Please Fill the Weight Estimated or Report.', 
        this.snackbar = true
      } 
      else if (this.preValueList.circumstances_leading_to_death == '' || this.preValueList.circumstances_leading_to_death == null) {
        this.errr10 = false
        return false, 
        this.snackbarText = 'Please Fill the Circumstances leading to death.', 
        this.snackbar = true
      } 
      else {
        this.errr6 = true
        this.errr7 = true
        this.errr8 = true
        this.errr9 = true
        this.errr10 = true
        this.errr11 = true
        this.errr12 = true
        this.errr13 = true
      }
      const data = {
        donor_id: this.$route.params.donor_id,
        userid: userId,
        // family_name: this.preValueList.family_name,
        // given_name: this.preValueList.given_name,
        dob: this.preValueList.dob,
        // age: this.preValueList.age,
        // sex: this.preValueList.sex,
        religion: this.preValueList.religion,
        height: this.preValueList.height,
        height_report: this.preValueList.height_report,
        weight: this.preValueList.weight,
        weight_report: this.preValueList.weight_report,
        mr_no: this.preValueList.mr_no,
        addimission_date_time: this.preValueList.addimission_date_time,
        // cause_of_death: this.preValueList.cause_of_death,
        circumstances_leading_to_death: this.preValueList.circumstances_leading_to_death,
        attending_physician: this.preValueList.attending_physician,
        // patient_given_donor_name: this.preValueList.patient_given_donor_name,
      }
      try {
        const response = await api.post(`donorNotification/save-form-a`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async FormAThird() {
        // if (
      //   this.preValueList.death_datetime_actual_estimated == '' ||
      //   this.preValueList.death_datetime_actual_estimated == null
      // ) {
      //   return false, (this.snackbarText = 'Please Fill the Death DateTime Actual/Estimated.'), (this.snackbar = true)
      // }
      if (
        this.preValueList.donor_on_respirator_vantilator == '' ||
        this.preValueList.donor_on_respirator_vantilator == null
      ) {
        this.errr14 = false
        return false, (this.snackbarText = 'Please Fill the Donor on respirator/vantilator.'), (this.snackbar = true)
      }
      if (this.preValueList.donor_on_respirator_vantilator == 'Yes') {
        if (this.preValueList.time_of_vantilator == '' || this.preValueList.time_of_vantilator == null) {
          this.errr15 = false
          return false, (this.snackbarText = 'Please Fill the How Long.'), (this.snackbar = true)
        } else {
          this.errr15 = true
        }
      }
      if (this.preValueList.history_of_hiv == '' || this.preValueList.history_of_hiv == null) {
        this.errr16 = false
        return false, (this.snackbarText = 'Please Fill the History of HIV.'), (this.snackbar = true)
      }
      if (this.preValueList.history_of_hbv == '' || this.preValueList.history_of_hbv == null) {
        this.errr17 = false
        return false, (this.snackbarText = 'Please Fill the History of HBV.'), (this.snackbar = true)
      }
      if (this.preValueList.history_of_hcv == '' || this.preValueList.history_of_hcv == null) {
        this.errr18 = false
        return false, (this.snackbarText = 'Please Fill the History of HCV.'), (this.snackbar = true)
      }
      if (this.preValueList.ocular_hx == '' || this.preValueList.ocular_hx == null) {
        this.errr19 = false
        return false, (this.snackbarText = 'Please Fill the Ocular Hx.'), (this.snackbar = true)
      }
      if (this.preValueList.ocular_hx == 'Yes') {
        if (this.preValueList.ocular_hx_description == '' || this.preValueList.ocular_hx_description == null) {
          return false, (this.snackbarText = 'Please Fill the Ocular Hx Description.'), (this.snackbar = true)
        }
      }
      if (this.preValueList.history_of_cancer == '' || this.preValueList.history_of_cancer == null) {
        this.errr20 = false
        return false, (this.snackbarText = 'Please Fill the History of cancer.'), (this.snackbar = true)
      }
      if (this.preValueList.history_of_cancer == 'Yes') {
        if (
          this.preValueList.history_of_cancer_description == '' ||
          this.preValueList.history_of_cancer_description == null
        ) {
          return false, (this.snackbarText = 'Please Fill the History of cancer Description.'), (this.snackbar = true)
        }
      }
      if (
        this.preValueList.dementia_alzheimer_cnd_deases == '' ||
        this.preValueList.dementia_alzheimer_cnd_deases == null
      ) {
        this.errr21 = false
        return false, (this.snackbarText = 'Please Fill the Dementia/Alzheimer/CND_deases.'), (this.snackbar = true)
      }
      if (this.preValueList.dementia_alzheimer_cnd_deases == 'Yes') {
        if (
          this.preValueList.dementia_alzheimer_cnd_deases_description == '' ||
          this.preValueList.dementia_alzheimer_cnd_deases_description == null
        ) {
          return (
            false,
            (this.snackbarText = 'Please Fill the Dementia/Alzheimer/CND_deases Description.'),
            (this.snackbar = true)
          )
        }
      }
      if (this.preValueList.isolation == '' || this.preValueList.isolation == null) {
        this.errr22 = false
        return false, (this.snackbarText = 'Please Fill the isolation.'), (this.snackbar = true)
      }
      if (this.preValueList.isolation == 'Yes') {
        if (this.preValueList.isolation_description == '' || this.preValueList.isolation_description == null) {
          return false, (this.snackbarText = 'Please Fill the isolation Description.'), (this.snackbar = true)
        }
      }
      if (this.preValueList.systematic_infection == '' || this.preValueList.systematic_infection == null) {
        this.errr23 = false
        return (
          false, (this.snackbarText = 'Please Fill the Signs/Symptoms of Systemic Infection.'), (this.snackbar = true)
        )
      }
      if (this.preValueList.systematic_infection == 'Yes') {
        if (
          this.preValueList.systematic_infection_description == '' ||
          this.preValueList.systematic_infection_description == null
        ) {
          return (
            false,
            (this.snackbarText = 'Please Fill the Signs/Symptoms of Systemic Infection Description.'),
            (this.snackbar = true)
          )
        }
      }
      if (this.preValueList.iv_fluid_last_hour == '' || this.preValueList.iv_fluid_last_hour == null) {
        this.errr24 = false
        return false, (this.snackbarText = 'Please Fill the IV fluid given in the last hour.'), (this.snackbar = true)
      }
      if (this.preValueList.iv_fluid_last_hour == 'Yes') {
        if (this.preValueList.iv_fluid_amount == '' || this.preValueList.iv_fluid_amount == null) {
          this.errr25 = false
          return false, (this.snackbarText = 'Please Fill the IV fluid amount in ml.'), (this.snackbar = true)
        } else {
          this.errr25 = true
        }
      }
      if (this.preValueList.blood_given_last_48hours == '' || this.preValueList.blood_given_last_48hours == null) {
        this.errr26 = false
        return false, (this.snackbarText = 'Please Fill the Blood Given in the last 48 hours.'), (this.snackbar = true)
      }
      if (this.preValueList.blood_given_last_48hours == 'Yes') {
        if (this.preValueList.blood_given_amount == '' || this.preValueList.blood_given_amount == null) {
          this.errr27 = false
          return (
            false,
            (this.snackbarText = 'Please Fill the Blood Given in the last 48 hours description.'),
            (this.snackbar = true)
          )
        } else {
          this.errr27 = true
        }
      }
      if (this.preValueList.transferred_from_other_facility == 'Yes') {
        if (this.preValueList.transferred_time == '' || this.preValueList.transferred_time == null) {
          this.errr28 = false
          return false, (this.snackbarText = 'Please Fill the Transferred from where/when ?.'), (this.snackbar = true)
        } else {
          this.errr28 = true
        }
      } else {
        this.errr14 = true
        this.errr16 = true
        this.errr17 = true
        this.errr18 = true
        this.errr19 = true
        this.errr20 = true
        this.errr21 = true
        this.errr22 = true
        this.errr23 = true
        this.errr24 = true
        this.errr26 = true
      }

      const data = {
        donor_id: this.$route.params.donor_id,
        userid: userId,
        death_datetime_actual_estimated: this.preValueList.death_datetime_actual_estimated,
        donor_on_respirator_vantilator: this.preValueList.donor_on_respirator_vantilator,
        time_of_vantilator: this.preValueList.time_of_vantilator,
        history_of_hiv: this.preValueList.history_of_hiv,
        history_of_hbv: this.preValueList.history_of_hbv,
        history_of_hcv: this.preValueList.history_of_hcv,
        history_of_cancer: this.preValueList.history_of_cancer,
        history_of_cancer_description: this.preValueList.history_of_cancer_description,
        dementia_alzheimer_cnd_deases: this.preValueList.dementia_alzheimer_cnd_deases,
        dementia_alzheimer_cnd_deases_description: this.preValueList.dementia_alzheimer_cnd_deases_description,
        ocular_hx: this.preValueList.ocular_hx,
        ocular_hx_description: this.preValueList.ocular_hx_description,
        isolation: this.preValueList.isolation,
        isolation_description: this.preValueList.isolation_description,
        systematic_infection: this.preValueList.systematic_infection,
        systematic_infection_description: this.preValueList.systematic_infection_description,
        iv_fluid_last_hour: this.preValueList.iv_fluid_last_hour,
        iv_fluid_amount: this.preValueList.iv_fluid_amount,
        blood_given_last_48hours: this.preValueList.blood_given_last_48hours,
        blood_given_amount: this.preValueList.blood_given_amount,
        transferred_from_other_facility: this.preValueList.transferred_from_other_facility,
        transferred_time: this.preValueList.transferred_time,
        medical_history: this.preValueList.medical_history,
        social_behavioral_hx: this.preValueList.social_behavioral_hx,
      }
      try {
        const response = await api.post(`donorNotification/save-form-a`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async FormAFour() {
      // if (this.preValueList.medico_legal_case == '' || this.preValueList.medico_legal_case == null) {
      //   this.errr29 = false
      //   return false, (this.snackbarText = 'Please Fill the Medico -Legal-case.'), (this.snackbar = true)
      // }
      if (this.preValueList.permission_for_donation == '' || this.preValueList.permission_for_donation == null) {
        this.errr30 = false
        return false, (this.snackbarText = 'Please Fill the Permission for donation.'), (this.snackbar = true)
      }
      if (this.preValueList.medico_legal_case == 'Yes') {
        // if (this.preValueList.police_medico_legal_case == '' || this.preValueList.police_medico_legal_case == null) {
        //   this.errr31 = false
        //   return false, (this.snackbarText = 'Please Fill the Polico/Medico -Legal-case.'), (this.snackbar = true)
        // }
        if (this.preValueList.medico_legal_facility == '' || this.preValueList.medico_legal_facility == null) {
          this.errr32 = false
          return false, (this.snackbarText = 'Please Fill the medico legal facility.'), (this.snackbar = true)
        }
        if (this.preValueList.released_by == '' || this.preValueList.released_by == null) {
          this.errr33 = false
          return false, (this.snackbarText = 'Please Fill the released by.'), (this.snackbar = true)
        }
        if (this.preValueList.released_by_phone == '' || this.preValueList.released_by_phone == null) {
          this.errr34 = false
          return false, (this.snackbarText = 'Please Fill the Phone No.'), (this.snackbar = true)
        }
        if (this.preValueList.released_by_phone.length < 10) {
          this.errr34 = false
          return false, (this.snackbarText = 'Please Enter 10 digit Phone No.'), (this.snackbar = true)
        }
        else {
          this.errr31 = true
          this.errr32 = true
          this.errr33 = true
          this.errr34 = true
        }
      }

      if (this.preValueList.autospy == '' || this.preValueList.autospy == null) {
        this.errr35 = false
        return false, (this.snackbarText = 'Please Fill the autospy.'), (this.snackbar = true)
      }
      if (this.preValueList.autospy == 'Yes') {
        if (this.preValueList.autospy_performed_by == '' || this.preValueList.autospy_performed_by == null) {
          this.errr36 = false
          return false, (this.snackbarText = 'Please Fill the autospy Performed By.'), (this.snackbar = true)
        }
        if (this.preValueList.autospy_location == '' || this.preValueList.autospy_location == null) {
          this.errr37 = false
          return false, (this.snackbarText = 'Please Fill the autospy location.'), (this.snackbar = true)
        } else {
          this.errr36 = true
          this.errr37 = true
        }
      } else {
        this.errr29 = true
        this.errr30 = true
        this.errr35 = true

      }
      const data = {
        donor_id: this.$route.params.donor_id,
        userid: userId,
        medico_legal_case: this.preValueList.medico_legal_case,
        permission_for_donation: this.preValueList.permission_for_donation,
        police_medico_legal_case: this.preValueList.police_medico_legal_case,
        medico_legal_facility: this.preValueList.medico_legal_facility,
        released_by: this.preValueList.released_by,
        released_by_phone: this.preValueList.released_by_phone,
        other_notes: this.preValueList.other_notes,
        autospy: this.preValueList.autospy,
        autospy_performed_by: this.preValueList.autospy_performed_by,
        autospy_location: this.preValueList.autospy_location,
      }
      try {
        const response = await api.post(`donorNotification/save-form-a`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async FormAFive() {
      const data = {
        donor_id: this.$route.params.donor_id,
        userid: userId,
        outcome: this.preValueList.outcome,
        // not_screened: this.preValueList.not_screened,
        not_screened_reason: this.preValueList.not_screened_reason,
        not_screened_reason_description: this.preValueList.not_screened_reason_description,
        // not_suitable: this.preValueList.not_suitable,
        not_suitable_reason: this.preValueList.not_suitable_reason,
        not_suitable_reason_description: this.preValueList.not_suitable_reason_description,
        not_suitable: this.not_suitable,
        not_screened: this.not_screened,
        not_approched: this.not_approched,
        // not_screened: '',
        // not_suitable: '',
        // not_approched: '',
        // not_approched: this.preValueList.not_approched,
        not_approched_reason: this.preValueList.not_approched_reason,
        not_approched_reason_description: this.preValueList.not_approched_reason_description,
      }
      try {
        const response = await api.post(`donorNotification/save-form-a`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status === true) {
          this.snackbarText = response.data.msg
          this.donorDeatilView()
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
